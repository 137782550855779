import AppRoutes from "./Routes"; // Note the change here to match the new component name
import "./App.css";


function App() {
  return (
    <div className="App">
      <AppRoutes />
    </div>
  );
}

export default App;